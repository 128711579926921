import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import ContactMain from '../components/contactMain'
import OfficeDepartments from '../components/cardIconsOffices'
import FormMap from '../components/formMap'

const Contact = ({ data, location }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark
  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Contact Us"
            description="Want more information on Macro 4 solutions and services?  Please get in touch using the contact details or web form provided."
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}            
          />
          <Hero
            class="intro-65"
            gradientClass="mask d-flex justify-content-center align-items-center gradient"
            image={heroImage.childImageSharp.gatsbyImageData}
            alt={post.frontmatter.alttext}            
            title={post.frontmatter.title}
            subtitle={post.frontmatter.subtitle}
            type="root"            
          />
        </div>

        <main>
          <section className="bg-gray-light">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <ContactMain />
            </MDBAnimation>
          </section>

          <section className="bg-white" id="departments">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h3 className="font-alt text-center font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.departments.title}
                  <span id="department-contacts" className="anchor">
                    &nbsp;
                  </span>  
                </h3>
                {post.frontmatter.departments.contactdeets && (
                  <MDBRow>
                    <>
                      {post.frontmatter.departments.contactdeets.map(
                        details => {
                          return (
                            <OfficeDepartments
                              depttitle={details.depttitle}
                              tel={details.tel}
                              tel1={details.tel1}
                              fax={details.fax}
                              email={details.email}
                              suptext={details.suptext}
                              formtext={details.formtext}
                              image={details.image.childImageSharp.gatsbyImageData}
                              alt={details.alttext}
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        </main>

      </Layout>
    </>
  )
}
export default Contact


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(frontmatter: { name: { eq: "contact" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        departments {
          title
          contactdeets {
            depttitle
            tel
            tel1
            fax
            email
            suptext
            formtext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 85, height: 85, quality: 90)
              }
            }
          }
        }
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`